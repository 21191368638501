.nav{
    background-color: black;
    
}
.nav ul{
    overflow: auto;
}
.nav li{
    float:left;
    list-style: none; 
    margin: 13px 20px;
    
}
.nav li a{
    padding: 3px 3px;
    text-decoration: none;
    color: white;
}
.nav li a:hover{
    color: #4db5ff
}
.search{
    float: right;
    color: white;
    padding: 12px 75px;
}
.nav input{
    border: 2px solid black;
    border-radius: 14px;
    padding: 3px 17px;
    width: 129px;
    background-color: white;
}
