.blog__container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2.5rem;
}
.blog__item{
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1 px solid transparent;
}
.blog__item:hover{
    border-color: var(--color-primary-varient);
    background: transparent;
}
.blog__item-image{
    border-radius: 1.5rem;
    overflow: hidden;
    height: 13rem;
}
.blog__item h3{
    margin: 1.2rem 0 2 rem;
}
.blog__item-cta{
    display: flex;
    gap: 1rem;
    margin-bottom: 1 rem;
}
.btn-primary{
    display: flex;
    align-items: center;
    justify-content: center;

}
@media screen and (max-width: 1024px){
    .blog__container{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 600px){
    .blog__container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    
}   