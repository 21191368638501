:root{
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary:#4db5ff;
    --color-primary-variant:rgba(77,181,255,0.4);
    --color-white:#fff;
    --color-light:rgba(255,255,255,0.6);
    --transition: all 400ms ease;
    --container-width-lg: 75%;
    --container-width-md:86%;
    --container-width-sm:90%;

}

.services__container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 3rem;   
    
}
.graphics{
    margin-left: 5rem;
    margin-right: 2rem;
}
.web{
    margin-left: 3rem;
    margin-right: 3rem;

}
.content{
    margin-left: 2rem;
    margin-right: 5rem;

}

.service{
    background: var(--color-bg-variant);
    border-radius:  0 0 2rem 2rem;
    border: 1px solod var(--color-primary);
    height: fit-content;
    transition: var(--transition);
}

.service:hover{
    background: transparent;
    border-color:rgba(77,181,255,0.4);
    cursor: default;
}

.service__head{
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.service__head h3{
    color: var(--color-bg);
    font-size: 1rem;
    text-align: center;
}

.service__list{
    padding: 2rem;
}

.service__list li{
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.service__list-icon{
    color: var(--color-primary);
    margin-top:2px
}

.service__list p{
    font-size: 0.9rem;
}

@media screen and (max-width: 1024px){
    .services__container{
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
    .service{
        height: auto;
    }
    .content{
        margin-left: 5rem;
        margin-right: 2.5rem;
    }
}

@media screen and (max-width: 600px){
    .services__container{
        grid-template-columns: 1fr;
        
    }
    .graphics{
        margin-right:5rem;

    }
    .web{
        margin-right: 5rem;
        margin-left: 5rem;

    }
    .content{
        margin-right: 5rem;
        margin-left: 5rem;

    }
}   